import { NavLink } from "react-router-dom";
import Navbar from "../../components/de/Navbar";
import json from "../../components/de/env.json";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
const editable = json;

const HomeDe = () => {
  return (
    <div>
      <Navbar /> {/* Navbar-Komponente */}
      {/* Start des Hero-Bereichs */}
      <div className="w-full h-screen bg-cover bg-center bg-bgb">
        <div className="backdrop-blur-lg flex justify-center items-center h-screen">
          <div className="max-xl:w-[80%] w-[40%]">
            <h1 className="text-center text-4xl font-bold tracking-tight text-white sm:text-6xl" data-aos="zoom-in">
              Zuverlässiges Hosting zu reduzierten Preisen
            </h1>
            <p className="text-center mt-6 text-lg leading-8 text-white" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
              By-Hoster wurde geschaffen, um Ihnen erschwingliche Tarife und qualitativ hochwertige Hosting-Dienste anzubieten, damit Sie Ihre größten Projekte verwirklichen können.
            </p>
            <p className="text-center mt-8" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
              <NavLink to="https://client.by-hoster.net/store" className="rounded-md bg-[#002BB7] p-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000C36] focus-visible:outline focus-visible:outline-2">
                <i className="fa-solid fa-cart-shopping text-white"></i> Shop
              </NavLink>
            </p>
          </div>
        </div>
      </div>
        {/* Start des Dienstleistungsbereichs */}
        <div className="text-white bg-bg-g w-full bg-cover bg-center">
          <div className="backdrop-blur-lg">
            <div>
              <div className="backdrop-blur-lg mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
                <div className="mx-auto max-w-lg text-center">
                  <h2
                    className="text-3xl font-bold sm:text-4xl"
                    data-aos="zoom-in"
                  >
                    Unsere Hosting-Dienstleistungen
                  </h2>
                  <p className="mt-4 text-gray-300" data-aos="zoom-in">
                    Eine Liste der Dienstleistungen, die wir anbieten
                  </p>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/plesk"
                  >
                    <i className="fa-solid fa-globe fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Webhosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Wir bieten Webhosting für Ihre Websites zu sehr
                      erschwinglichen Preisen an.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/vps"
                  >
                    <i className="fa-solid fa-server fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      VPS-Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Wir bieten VPS zu sehr erschwinglichen Tarifen mit
                      hochwertigem Anti-DDoS-Schutz an!
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/GameBox"
                  >
                    <i className="fa-solid fa-gamepad fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      GameBox-Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Sie können mehrere Spiele-Server und mehr ohne die
                      Komplexität der VPS-Verwaltung erstellen und verwalten,
                      dank unseres Panels.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/gtarp"
                  >
                    <img
                      src="/img/fivem.svg"
                      className="w-10"
                      alt="fivem vectorielles Icon"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      FiveM-Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Wir bieten FiveM-Server mit hoher Leistung für eine
                      optimale Spielqualität an!
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/minecraft"
                  >
                    <img
                      src="/img/minecraft.svg"
                      className="w-8"
                      alt="minecraft vectorielles Icon"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Minecraft-Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Wir bieten Minecraft-Server an, damit Sie ohne Sorgen um
                      die Leistung einen Server für Freunde oder sogar zum
                      öffentlichen Spielen erstellen können!
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/gmod"
                  >
                    <img
                      src="/img/gmod.svg"
                      className="w-10"
                      alt="garry's mod vectorielles Icon"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Garry's Mod-Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Wir bieten Garry's Mod-Server für einfaches und
                      leistungsstarkes Gameplay an.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/rust"
                  >
                    <img
                      src="/img/rust.svg"
                      className="w-10"
                      alt="rust vectorielles Icon"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Rust-Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Wir bieten leistungsstarke Rust-Server für ein optimales
                      Spielerlebnis mit unvergleichlicher Spielqualität an.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/nodejs"
                  >
                    <i className="fa-brands fa-node-js fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      NodeJS-Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Wir bieten NodeJS-Pakete an, wenn Sie ein Discord-Bot-Entwickler
                      oder für andere Tests sind, mit qualitativ hochwertiger
                      geringer Latenz zu erschwinglichen Preisen für den Start.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/python"
                  >
                    <i className="fa-brands fa-python fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Python-Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Wir bieten Python3-Pakete an, wenn Sie ein Discord-Bot-Entwickler
                      oder für andere Tests sind, mit qualitativ hochwertiger
                      geringer Latenz zu erschwinglichen Preisen für den Start.
                    </p>
                  </a>
                </div>
                <div className="mt-12 text-center">
                  <NavLink
                    to="https://client.by-hoster.net/register"
                    className="p-2 rounded-md bg-[#002BB7] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000C36] focus-visible:outline focus-visible:outline-2"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    Registrieren{" "}
                    <i className="fa-solid fa-arrow-right text-white"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <hr className="mx-auto w-[80%]" />

          {/* Start des Statistikbereichs */}

          <div className="backdrop-blur-lg py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <dl
                className="grid grid-cols-1 gap-x-2 gap-y-16 text-center lg:grid-cols-4"
              >
                {editable.stats.map((stat) => (
                  <div
                    key={stat.id}
                    className="mx-auto flex max-w-xs flex-col gap-y-4"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <dt className="text-base leading-7 text-gray-200">
                      {stat.nom}
                    </dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                      <i className={stat.fontawsome}></i>
                    </dd>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                      {stat.chiffre}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* Start des Präsentationsbereichs */}

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg">
            <section
              id="features"
              className="relative block px-6 py-10 md:py-20 md:px-10"
            >
              <div className="relative mx-auto max-w-5xl text-center">
                <h2
                  className="text-3xl font-bold sm:text-4xl"
                  data-aos="zoom-in"
                >
                  Warum By-Hoster wählen?
                </h2>
                <p className="mt-4 text-gray-300" data-aos="zoom-in">
                  By-Hoster ist der ideale französische Hosting-Anbieter für
                  Einzelpersonen, Enthusiasten und Unternehmen. Die Leistung
                  unserer Server ermöglicht es Ihnen, maximale Geschwindigkeit
                  für Ihre Webprojekte zu haben!
                </p>
              </div>
              <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-database fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Technologie
                  </h2>
                  <p className="mt-1 text-sm text-gray-300">
                    Unsere Angebote werden von den neuesten Technologien
                    angetrieben, um Stabilität für Ihre Dienste zu gewährleisten.
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-cloud-arrow-up fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">Backup</h2>
                  <p className="mt-1 text-sm text-gray-300">
                    Wir verfügen über ein automatisches und geplantes
                    Backup-System jeden Tag, um Ihre Daten zu sichern.
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-phone-volume fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">Support</h2>
                  <p className="mt-1 text-sm text-gray-300">
                    Kundensupport ist unsere Priorität. Wir bemühen uns, unseren
                    Kunden den bestmöglichen Support zu bieten.
                  </p>
                </div>
              </div>
              <div className="absolute bottom-0 left-0 z-0 h-1/3 w-full"></div>
              <div className="absolute bottom-0 right-0 z-0 h-1/3 w-full"></div>
            </section>
          </div>

          {/* Start des FAQ-Bereichs */}

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg py-8 sm:py-12">
            <h1
              className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
              data-aos="zoom-in"
            >
              Häufig gestellte Fragen.
            </h1>
            <p
              className="mt-4 text-gray-300 mb-4 text-center"
              data-aos="zoom-in"
            >
              Antworten auf die häufigsten Fragen.
            </p>
            <div
              className="mt-12 xl:flex justify-center xl:items-center"
              data-aos="fade-up"
              data-aos-easing="ease"
            >
              <img
                src="/img/faq.webp"
                className="xl:mr-28 max-sm:w-64 w-96 cloudeffect max-xl:mx-auto max-xl:py-8"
                alt="Bild des FAQ-Bereichs"
              />
              <div className="grid divide-y divide-neutral-200 max-w-xl max-sm:w-64 max-xl:mx-auto">
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="200"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span className="xl:pr-[227px]">
                        Haben Sie einen qualitativ hochwertigen Anti-DDoS-Schutz?
                      </span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Ja, natürlich! Wir haben sowohl Schutz der Schicht 4 als
                      auch der Schicht 7 gegen DDoS-Angriffe.
                    </p>
                  </details>
                </div>
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="400"
                >

                <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span>Wo sind Ihre Maschinen gehostet?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Die gesamte Infrastruktur befindet sich in Frankreich,
                      gehört By-Hoster und ist genau in der Region
                      "Nouvelle-Aquitaine" positioniert.
                    </p>
                  </details>
                </div>
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="600"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span>Bieten Sie 24/7-Support an?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Unser Support ist jeden Tag aktiv und bemüht sich, Ihnen so schnell wie möglich zu antworten!
                    </p>
                  </details>
                </div>
              </div>
            </div>
          </div>

        {/* Beginn des Kundenbewertungen-Bereichs */}
        <hr className="mx-auto w-[80%]" />

        <div className="backdrop-blur-lg py-8">
          <div className="backdrop-blur-lg mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
            <h1
              className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
              data-aos="zoom-in"
            >
              Kundenbewertungen
            </h1>
            <p
              className="mt-4 text-gray-300 mb-8 text-center"
              style={{ fontStyle: "italic" }}
              data-aos="zoom-in"
            >
              Einige Bewertungen wurden zur Klarheit paraphrasiert, jedoch wurde die Essenz
              und die allgemeine Meinung der Kunden beibehalten. Die Änderungen
              haben die Bedeutung oder Integrität der ursprünglichen Kommentare nicht verändert.
              Die Bewertungen wurden aus dem Französischen übersetzt.
            </p>
            <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
              <div
                className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                data-aos="fade-up"
                data-aos-easing="ease"
              >
                <div className="flex justify-center">
                  <img
                    src="/img/user.webp"
                    className="w-12 rounded-md"
                    alt="Benutzericon von top-heberg.com"
                  />
                </div>
                <h2 className="mt-4 text-xl font-bold text-white">
                  Tom Marcos
                </h2>
                <div className="flex justify-center mb-4">
                  {/* Sterne */}
                </div>
                <p className="mt-1 text-sm text-gray-300">
                  "Ich habe einen VPS bei ihnen gekauft, konnte jedoch das Pterodactyl-Panel nicht einrichten. Nachdem ich ein Ticket eröffnet hatte, wurde es innerhalb von 10 Minuten eingerichtet. Deshalb empfehle ich By-Hoster wärmstens!"
                </p>
              </div>
              <div
                className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                data-aos="fade-up"
                data-aos-easing="ease"
              >
                <div className="flex justify-center">
                  <img
                    src="/img/user.webp"
                    className="w-12 rounded-md"
                    alt="Benutzericon von top-heberg.com"
                  />
                </div>
                <h2 className="mt-4 text-xl font-bold text-white">
                  Le1er Xero
                </h2>
                <div className="flex justify-center mb-4">
                  {/* Sterne */}
                </div>
                <p className="mt-1 text-sm text-gray-300">
                  "Ein Hosting-Anbieter, der seine Kunden respektiert, mit einem sehr reaktionsschnellen Support und einem ausgezeichneten Hosting-Service. Ich denke, ich kann sagen, dass es sich um einen qualitätsvollen Hosting-Anbieter handelt!"
                </p>
              </div>
              <div
                className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                data-aos="fade-up"
                data-aos-easing="ease"
              >
                <div className="flex justify-center">
                <img
                    src="/img/user.webp"
                    className="w-12 rounded-md"
                    alt="Benutzericon von top-heberg.com"
                  />
                </div>
                <h2 className="mt-4 text-xl font-bold text-white">
                  Nain Ptit
                </h2>
                <div className="flex justify-center mb-4">
                  {/* Sterne */}
                </div>
                <p className="mt-1 text-sm text-gray-300">
                  "Ich nutze diesen Hosting-Anbieter seit nicht allzu langer Zeit für eine Minecraft-Serverseite und finde ihn super praktisch. Es gibt praktisch keine Verzögerungen bei der günstigsten Version."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Ende des Kundenbewertungen-Bereichs */}

        {/* Beginn des Footer-Bereichs */}
        <footer className="bg-footerbg w-full bg-cover bg-center">
          <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
              <div>
                <img
                  src="/img/logo-big.webp"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  className="w-44"
                  alt="By-Hoster Logo horizontal"
                />
                <p
                  className="mt-4 max-w-xs text-white"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  By-Hoster wurde 2020 von Planetium Network gegründet, um Ihnen erschwingliche Tarife und qualitativ hochwertige Hosting-Dienste anzubieten, damit Sie Ihre größten Projekte verwirklichen können.
                </p>
                <ul className="mt-8" data-aos="fade-up" data-aos-easing="ease">
                  <li>
                    <a
                      href="mailto:contact@by-hoster.com"
                      rel="noreferrer"
                      target="_blank"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      <p className="">
                        <i className="fa-solid fa-envelope"></i> contact@by-hoster.com
                      </p>
                    </a>
                  </li>
                  <li>
                    <div className="text-gray-300 transition hover:opacity-75">
                      <p>
                        <i className="fa-solid fa-globe"></i> www.by-hoster.net
                      </p>
                    </div>
                  </li>
                  <li>
                    <a
                      href="tel:+33972115049"
                      rel="noreferrer"
                      target="_blank"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      <p>
                        <i className="fa-solid fa-phone"></i> +33972115049
                      </p>
                    </a>
                  </li>
                  <li className="mt-8">
                    <a
                      href="https://www.top-heberg.com/hebergeur/by-hoster-300"
                      target="_blank"
                      title="By Hoster auf Top-heberg.com"
                    >
                      <img
                        src="https://www.top-heberg.com/badge/by-hoster-300?s=refer"
                        width="250"
                        alt="Top-heberg.com"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4"
                data-aos="fade-up"
                data-aos-easing="ease"
              >
                <div>
                  <p className="font-medium text-white">Gaming-Services</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/GameBox"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        GameBox Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/gtarp"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        GTA RP Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/minecraft"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Minecraft Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/gmod"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Garry's Mod Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/rust"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Rust Hosting
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Cloud-Services</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/vps"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        VPS Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/plesk"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        WEB Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/nodejs"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        NodeJS Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/python"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Python Hosting
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Weitere Links</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/client"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Kundenbereich
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://by-h.cloud"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        ByCloud
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://statut.by-hoster.net"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Status
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:contact@by-hoster.com"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Kontakt
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Rechtliches (auf Französisch)</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="/CGU.pdf"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Allgemeine Geschäftsbedingungen
                      </a>
                    </li>
                    <li>
                      <a
                        href="/ML.pdf"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Impressum
                        </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default HomeDe;
